export const useSocialAuth = () => {
    const {
        public: { social_auth_keys },
    } = useRuntimeConfig()
    useHead({
        script: [
            {
                src: 'https://accounts.google.com/gsi/client',
                async: true,
            },
            {
                innerHTML: `window.fbAsyncInit = function() { FB.init({ appId: ${social_auth_keys.facebook}, xfbml: true, version: 'v18.0' });};`,
                hid: 'head-facebook-init',
            },
            {
                src: 'https://connect.facebook.net/en_US/sdk.js',
                async: true,
                defer: true,
                crossorigin: 'anonymous',
            },
        ],
    })

    const googleAuthInit = (
        callback: (err: any, response?: google.accounts.oauth2.TokenResponse) => void,
    ) => {
        try {
            const initTokenClientConfig: google.accounts.oauth2.TokenClientConfig = {
                client_id: social_auth_keys.google,
                scope: 'openid profile email',
                callback: (response: any) => {
                    callback(null, response)
                },
            }

            const client = google.accounts.oauth2.initTokenClient(initTokenClientConfig)

            client.requestAccessToken()
        } catch (err: any) {
            callback(err)
        }
    }

    const facebookAuthInit = (callback: (err: any, response?: fb.StatusResponse) => void) => {
        try {
            FB.login(
                function (response) {
                    if (response.status === 'connected') {
                        callback(null, response)
                    }
                },
                { scope: 'email' },
            )
        } catch (err: any) {
            callback(err)
        }
    }

    return {
        googleAuthInit,
        facebookAuthInit,
    }
}
